<template>
  <common-modal
    @close="$emit('close')"
    width="lg:w-common-modal"
    height="h-max">
    <div class="profile-edit">
      <div class="profile-edit-body">
        <template
          v-if="branding.features && branding.features.allowProfileVideo">
          <VideoRecorder
            :videoSrc="videoSrc"
            :userHidden="userHidden"
            :isAmbassador="true"
            @update="onUpdateVideoStory"
            @dirty="isUserStoryDirty = true" />
          <div class="profile-edit-divider"></div>
        </template>
        <ProfileReadMyStory
          :stories="this.user.FAQ || cachedUser.FAQ"
          @update="onUpdateFaq" />
      </div>

      <common-button
        class="profile-edit-save"
        behavior="grow"
        @click="onSave"
        :disabled="!isDirty"
        :loading="pollVideoStoryInterval">
        {{ $lang.general_Save }}
      </common-button>
    </div>
  </common-modal>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_BRANDING, GET_CURRENT_USER } from "@/store/getters.type";
  import api from "@/services/api/index";

  import ProfileReadMyStory from "@/components/dashboard/ambassadors/ambassadorsComponents/ProfileReadMyStory.vue";
  import VideoRecorder from "@/components/common/VideoRecorder.vue";
  import { SET_AMBASSADOR } from "@/store/actions.type";

  export default {
    name: "EditStoryModal",
    components: {
      VideoRecorder,
      ProfileReadMyStory,
    },
    props: {
      user: Object,
    },
    data() {
      return {
        userId: null,
        cachedUser: {
          first: "",
          last: "",
          FAQ: [],
        },
        videoSrc: null,
        userHidden: false,
        videoRecorderData: null,
        isUserFormDirty: false,
        isUserStoryDirty: false,
        videoStoryId: null,
        ready: false,
        pollVideoStoryInterval: null,
        deleteVideo: false,
      };
    },
    computed: {
      ...mapGetters({
        branding: GET_BRANDING,
        currentUser: GET_CURRENT_USER,
      }),
      isDirty() {
        return this.isUserFormDirty || this.isUserStoryDirty;
      },
    },
    async mounted() {
      this.userId = this.user._id || this.user.id;

      if (this.branding.features?.allowProfileVideo) {
        const response = await api.dashboard.getVideoStory({
          id: this.userId,
        });

        if (response && response.status === 200) {
          const userStories = response.data?.sort(
            (videoA, videoB) =>
              new Date(videoB.updatedAt) - new Date(videoA.updatedAt)
          );
          const userStory = userStories.length > 0 ? userStories[0] : null;
          this.userHidden = userStory?.userHidden;
          this.videoSrc = userStory?.video?.mp4Url ?? null;
          this.videoStoryId = userStory?._id ?? null;
        }
      }
    },
    methods: {
      onUpdateFaq(FAQ) {
        this.cachedUser.FAQ = FAQ;
        this.isUserFormDirty = true;
      },
      async onSave() {
        try {
          if (this.isUserFormDirty) {
            const result = await this.$store.dispatch(SET_AMBASSADOR, {
              id: this.userId,
              FAQ: this.cachedUser.FAQ,
            });
            if (!result) {
              return this.$toasted.show(
                this.$lang.general_ToastError,
                this.$toastedFailure
              );
            }
          }
          if (this.isUserStoryDirty) {
            if (this.videoRecorderData.videoData) {
              const formData = new FormData();
              formData.append("file", this.videoRecorderData.videoData);
              formData.append("userID", this.userId);
              formData.append("userHidden", this.videoRecorderData.userHidden);
              await api.dashboard.createVideoStory(formData);

              this.$toasted.show(
                this.$lang.editProfile_Processing,
                this.$toastedSuccess
              );
              this.$emit("close");

              // wait for video to be processed and available
              this.pollVideoStoryInterval = setInterval(async () => {
                let videoID = await this.pollVideoStory();

                if (videoID) {
                  this.videoStoryId = videoID;

                  this.$toasted.show(
                    this.$lang.editProfile_Success,
                    this.$toastedSuccess
                  );
                  this.$emit("refresh");
                }
              }, 2000);
            } else if (this.deleteVideo && this.videoStoryId) {
              const response = await api.dashboard.deleteVideoStory({
                userVideoStoryID: this.videoStoryId,
              });
              if (response.status === 200) {
                this.$toasted.show(
                  this.$lang.editProfile_Success,
                  this.$toastedSuccess
                );
                this.$emit("refresh");
                this.$emit("close");
              } else {
                this.$toasted.show(
                  this.$lang.general_ToastError,
                  this.$toastedFailure
                );
              }
            } else {
              const data = {
                userVideoStoryID: this.videoStoryId,
                userHidden: this.videoRecorderData.userHidden,
              };

              const response = await api.dashboard.updateVideoStory(data);
              if (response.status === 200) {
                this.$toasted.show(
                  this.$lang.editProfile_Success,
                  this.$toastedSuccess
                );
                this.$emit("refresh");
                this.$emit("close");
              } else {
                this.$toasted.show(
                  this.$lang.general_ToastError,
                  this.$toastedFailure
                );
              }
            }
          } else {
            this.$emit("close");
          }
        } catch (error) {
          console.log(error);
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
      onUpdateVideoStory(value) {
        this.videoRecorderData = value;
        this.userHidden = value.userHidden;

        if (value.delete) {
          this.deleteVideo = true;
          this.videoSrc = null;
        }
      },
      async pollVideoStory() {
        const response = await api.dashboard.getVideoStory({
          id: this.userId,
        });

        if (response?.status === 200) {
          let videoStories = response.data;

          if (videoStories?.length && videoStories[videoStories.length - 1]) {
            let videoStoryDoc = videoStories[videoStories.length - 1];
            if (videoStoryDoc.videoStatus === "cloudinary") {
              return;
            }

            clearInterval(this.pollVideoStoryInterval);
            return videoStoryDoc._id;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $content-width: 1000px;

  .profile-edit {
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;

      @media (max-width: $xs) {
        margin: 40px auto;
      }
    }

    &-card {
      margin: 0 auto;
      max-width: $content-width;
      background-color: $white;
      border-radius: 20px 20px 0 0;
      box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
      padding: 64px 30px 34px 30px;
      box-sizing: border-box;

      @media (max-width: $xs) {
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0 30px 34px 30px;
      }
    }

    &-body {
      margin: 0 auto;
      max-width: 600px;
    }

    &-divider {
      border-top: solid 1px $grey-200;
      width: 100%;
      margin: 48px 0;
    }

    &-save {
      width: 164px;
      margin: 0 auto;
    }

    &-footer {
      margin: 0 auto;
      max-width: $content-width;
      background-color: $white;
      border-top: solid 1px $grey-200;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
      padding: 20px 0;

      @media (max-width: $xs) {
        margin: 0;
        border-radius: 0;
      }
    }

    @media (max-width: $xs) {
      background-color: $white;
      margin: 0;
    }
  }
</style>
