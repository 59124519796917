<template>
  <div>
    <h2 class="video-recorder-title">
      {{ $lang.editProfile_watchMyStoryTitle }}
    </h2>
    <div class="video-recorder-description">
      {{ $lang.editProfile_watchMyStoryDescription }}
    </div>

    <!-- Video -->
    <div class="video-recorder">
      <div
        v-if="[STATES.INITIAL, STATES.PREVIEW].includes(state)"
        :class="['video-overlay', { grey: state === STATES.INITIAL }]">
        <div v-if="loading" class="video-overlay grey">
          <div class="loader"></div>
        </div>
        <template v-else>
          <template v-if="state === STATES.INITIAL">
            <div v-if="supported">
              <common-button
                class="video-overlay-button"
                variant="outline"
                :icon="true"
                @click="onPreview">
                <template slot="icon"><mdicon name="record" /></template>
                {{ $lang.videoRecording_Record }}
              </common-button>
              <div class="divider">
                <div class="or">{{ $lang.videoRecording_Divider }}</div>
              </div>
            </div>
            <common-button
              class="video-overlay-button"
              variant="outline"
              :icon="true"
              @click="onUpload">
              <template slot="icon"><mdicon name="upload" /></template>
              {{ $lang.videoRecording_Upload }}
            </common-button>
            <div class="note">
              {{ $lang.videoRecording_Note.format(maxFileSizeMb) }}
            </div>
          </template>
          <common-button
            v-if="state === STATES.PREVIEW"
            class="icon-change-camera"
            variant="secondary"
            :icon="true"
            :iconOnly="true"
            @click="changeCameras">
            <template slot="icon"><mdicon name="camera-flip" /></template>
          </common-button>
        </template>
      </div>
      <common-button
        v-else
        class="delete-button"
        variant="secondary"
        :iconOnly="true"
        @click="onDelete">
        <template slot="icon"><mdicon name="delete" /></template>
      </common-button>
      <video ref="video" playsinline class="video"></video>
    </div>

    <!-- Controls -->
    <div v-if="state === STATES.PREVIEW" class="menu">
      <common-button
        class="left"
        variant="secondary"
        width="grow"
        @click="onCancel"
        >{{ $lang.general_Cancel }}
      </common-button>
      <common-button
        variant="secondary"
        width="grow"
        :icon="true"
        @click="onReady">
        <template slot="icon">
          <mdicon class="icon-red" name="record" />
        </template>
        {{ $lang.videoRecording_Start }}
      </common-button>
    </div>
    <div v-if="state === STATES.PLAYBACK" class="menu">
      <common-button
        class="left"
        variant="secondary"
        width="grow"
        :icon="true"
        @click="onUpload">
        <template slot="icon"><mdicon name="upload" /></template>
        {{ $lang.videoRecording_UploadNew }}
      </common-button>
      <common-button
        v-if="supported"
        variant="secondary"
        :icon="true"
        width="grow"
        @click="onPreview">
        <template slot="icon"><mdicon name="record" /></template>
        {{ $lang.videoRecording_RecordNew }}
      </common-button>
    </div>
    <div
      v-if="state === STATES.PLAYBACK && isAmbassador"
      class="video-recorder-toggle">
      <common-toggle
        id="video-toggle"
        :value="!userHidden"
        @input="
          $emit('update', { userHidden: !$event, videoData });
          $emit('dirty', true);
        " />
      <label class="profile-label font-secondary" for="video-toggle">
        {{ $lang.videoRecording_UserHidden }}
      </label>
    </div>
    <div v-if="state === STATES.READY" class="menu border between">
      <span class="timeout">{{ $lang.videoRecording_Timeout }}</span>
      <div class="countdown">
        <div class="countdown-text">{{ this.timer }}</div>
      </div>
      <common-button
        class="right"
        variant="secondary"
        width="grow"
        @click="onCancelReady"
        >{{ $lang.general_Cancel }}</common-button
      >
    </div>
    <div v-if="state === STATES.RECORDING" class="menu border">
      <common-button
        :class="{ paused: isPaused }"
        variant="primary"
        :icon="true"
        :iconOnly="true"
        :tooltip="isPaused ? 'Resume' : 'Pause'"
        @click="() => (isPaused ? onResume() : onPause())">
        <template slot="icon"><mdicon name="pause" /></template>
      </common-button>

      <common-button
        variant="primary"
        :icon="true"
        :iconOnly="true"
        tooltip="Stop"
        @click="onStop">
        <template slot="icon"><mdicon name="stop" /></template>
      </common-button>
      <div class="timer font-primary-bold">
        <mdicon name="record" class="icon-red" />
        <span class="recording-time">{{ getMinsSeconds }}</span>
      </div>
      <common-button
        variant="primary"
        :icon="true"
        :iconOnly="true"
        tooltip="Restart"
        @click="onRestart">
        <template slot="icon"><mdicon name="restart" /></template>
      </common-button>
    </div>
  </div>
</template>

<script>
  const STATES = Object.freeze({
    INITIAL: "initial",
    PREVIEW: "preview",
    READY: "ready",
    RECORDING: "recording",
    PLAYBACK: "playback",
  });

  export default {
    props: {
      videoSrc: {
        type: String,
        default: null,
      },
      userHidden: {
        type: Boolean,
        default: false,
      },
      isAmbassador: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        supported: false,
        videoReference: null,
        mediaRecorder: null,
        options: {},
        videoDevices: [],
        stream: null,
        constraints: {
          audio: true,
          video: {
            width: 1920,
            height: 1080,
            facingMode: "user",
          },
        },
        videoData: null,
        state: STATES.INITIAL,
        timer: 3,
        timerInterval: null,
        recordingDurationSeconds: 0,
        segmentDurationSeconds: 0,
        isPaused: false,
        maxFileSizeMb: 300,
      };
    },
    created() {
      this.STATES = STATES;
      Object.freeze(this.STATES);
    },
    watch: {
      videoSrc() {
        this.defaultState();
      },
      async state(val) {
        const video = this.videoReference;

        switch (val) {
          case this.STATES.INITIAL:
            video.src = null;
            video.srcObject = null;
            video.controls = false;
            video.muted = true;
            break;
          case this.STATES.PREVIEW:
            this.loading = true;
            this.timer = 3;
            this.resetDuration();
            if (await this.setStream()) {
              clearInterval(this.timerInterval);
              video.src = null;
              video.srcObject = this.stream;
              video.controls = false;
              video.muted = true;
              video.play();
            }
            this.loading = false;
            break;
          case this.STATES.READY:
            this.timerInterval = setInterval(() => {
              this.timer--;
              if (this.timer <= 0) {
                this.state = this.STATES.RECORDING;
                clearInterval(this.timerInterval);
                this.timer = 3;
                this.record();
              }
            }, 1000);
            break;
          case this.STATES.RECORDING:
            video.src = null;
            video.srcObject = this.stream;
            video.controls = false;
            video.muted = true;
            video.play();
            this.$emit("dirty", true);
            break;
          case this.STATES.PLAYBACK:
            this.stream?.getTracks().forEach((track) => track.stop());
            this.resetDuration();
            video.src = this.videoSrc || URL.createObjectURL(this.videoData);
            video.srcObject = null;
            video.controls = true;
            video.muted = false;
            this.$emit("complete", true);
            break;
          default:
        }
      },
      videoData(val) {
        this.$refs.video.src = this.videoData
          ? URL.createObjectURL(this.videoData)
          : null;
        this.$emit("update", { userHidden: this.userHidden, videoData: val });
      },
    },
    mounted() {
      try {
        this.videoReference = this.$refs.video;
        this.supported = MediaRecorder !== undefined;

        if (this.supported) {
          this.init();
        }
      } catch (e) {
        this.supported = false;
      }
    },
    methods: {
      async init() {
        try {
          this.defaultState();
          const codecs = this.getSupportedMimeTypes();
          this.options = { mimeType: codecs[0] };
        } catch (e) {
          console.error(e);
        }
      },
      async setStream() {
        try {
          this.stream = await navigator.mediaDevices.getUserMedia(
            this.constraints
          );
          return true;
        } catch (err) {
          this.$toast.error(this.$lang.videoRecording_CameraError);
          this.defaultState();
          return false;
        }
      },
      getSupportedMimeTypes() {
        const possibleTypes = [
          "video/webm;codecs=vp9,opus",
          "video/webm;codecs=vp8,opus",
          "video/webm;codecs=h264,opus",
          "video/mp4;codecs=h264,aac",
        ];
        return possibleTypes.filter((mimeType) => {
          return MediaRecorder.isTypeSupported(mimeType);
        });
      },
      async getDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
      },
      defaultState() {
        this.state = this.videoSrc ? STATES.PLAYBACK : STATES.INITIAL;
      },
      onPreview() {
        this.state = this.STATES.PREVIEW;
      },
      onCancel() {
        this.defaultState();
      },
      onReady() {
        this.state = this.STATES.READY;
      },
      onCancelReady() {
        this.state = this.STATES.PREVIEW;
      },
      record() {
        this.state = this.STATES.RECORDING;
        const buffer = [];

        try {
          this.mediaRecorder = new MediaRecorder(this.stream, this.options);
        } catch (e) {
          console.error(e);
          return;
        }

        this.mediaRecorder.onstop = () => {
          this.videoData = new Blob(buffer, this.options);
          this.state = this.STATES.PLAYBACK;
        };
        this.mediaRecorder.ondataavailable = (event) => {
          if (
            this.state === STATES.RECORDING &&
            !this.isPaused &&
            event.data &&
            event.data.size > 0
          ) {
            buffer.push(event.data);
            this.segmentDurationSeconds = Math.floor(
              (Date.now() - this.recordingStart) / 1000
            );
          }
        };
        this.mediaRecorder.start(1000);
        this.recordingStart = Date.now();
      },
      onStop() {
        this.mediaRecorder.stop();
      },
      onPause() {
        this.mediaRecorder.pause();
        this.isPaused = true;
        this.recordingDurationSeconds += this.segmentDurationSeconds;
        this.segmentDurationSeconds = 0;
      },
      onResume() {
        this.mediaRecorder.resume();
        this.isPaused = false;
        this.recordingStart = Date.now();
      },
      onRestart() {
        this.videoData = null;
        this.state = this.STATES.PREVIEW;
      },
      onDelete() {
        this.videoData = null;
        this.state = this.STATES.INITIAL;

        this.$emit("update", { delete: true });
        this.$emit("dirty", true);
      },
      async changeCameras() {
        const mode = this.constraints.video.facingMode;
        this.constraints.video.facingMode =
          mode === "user" ? "environment" : "user";
        this.stream = await navigator.mediaDevices.getUserMedia(
          this.constraints
        );
        this.videoReference.srcObject = this.stream;
        this.videoReference.play();
      },
      onUpload() {
        const element = document.createElement("input");
        element.type = "file";
        element.accept = "video/*";
        element.onchange = () => {
          if (element.files.length) {
            const sizeInMb = element.files[0].size / 1000000;
            if (sizeInMb <= this.maxFileSizeMb) {
              this.videoData = element.files[0];
              this.state = this.STATES.PLAYBACK;
              this.$emit("dirty", true);
            } else {
              this.$toast.error(
                this.$lang.videoRecording_ErrorMaxFileSize.format(
                  this.maxFileSizeMb
                )
              );
            }
          }
        };
        element.click();
      },
      resetDuration() {
        this.recordingDurationSeconds = 0;
        this.segmentDurationSeconds = 0;
        this.isPaused = false;
      },
    },
    computed: {
      elapsedRecordingSeconds() {
        return this.recordingDurationSeconds
          ? this.recordingDurationSeconds + this.segmentDurationSeconds
          : this.segmentDurationSeconds;
      },
      getMinsSeconds() {
        const minutes = Math.floor(this.elapsedRecordingSeconds / 60)
          .toString()
          .padStart(2, "0");
        const seconds = (this.elapsedRecordingSeconds % 60)
          .toString()
          .padStart(2, "0");
        return `${minutes}:${seconds}`;
      },
    },
    async beforeDestroy() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
      }
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
    },
  };
</script>

<style scoped lang="scss">
  @import "@/scss/_typography.scss";

  // 16:9 ratios
  $player-width: 395px;
  $player-height: 222px;
  $player-max-height: 702px;

  $animation-duration: 1s;

  .video-recorder-title {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $grey-800;
  }

  .video-recorder-description {
    margin-bottom: 2rem;
    text-align: left;
    color: $grey-500;
    font-size: 14px;
  }

  .video-recorder {
    position: relative;
    margin: 0 auto 28px;
    width: 100%;
    max-width: calc(#{$player-width} + 1px);
    min-height: calc(#{$player-height} + 1px);
    max-height: $player-max-height;
    display: flex;
    justify-content: center;

    .delete-button {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;
    }
  }
  .video {
    width: 100%;
    max-width: $player-width;
    min-height: $player-height;
    max-height: $player-max-height;
    background: $black;
    border-radius: 8px;
  }

  .video-overlay {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    &.grey {
      background: $grey-200;
      border-radius: 6px;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    .video-overlay-button {
      width: 164px;
    }

    .divider {
      @extend .font-secondary;
      border: solid 1px $grey-500;
      height: 0;
      width: 146px;
      margin: 16px auto;
    }

    .or {
      transform: translateY(-50%);
      margin: 0 auto;
      padding: 0 12px;
      background-color: $grey-200;
      width: fit-content;
    }

    .note {
      @extend .font-secondary;
      margin-top: 8px;
    }
  }

  .menu {
    margin: 0 auto 28px auto;
    max-width: $player-width;
    display: flex;
    box-sizing: border-box;
    justify-content: space-evenly;

    &.border {
      border: 1px solid $grey-200;
      border-radius: 8px;
      padding: 10px;
    }

    &.between {
      justify-content: space-between;
    }

    .left {
      margin-right: 14px;
    }

    .right {
      margin-left: 10px;
    }

    .timeout {
      margin: auto 16px auto 10px;
    }

    .countdown {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: $blue-500;
      color: $white;
      line-height: 48px;
      font-weight: 500;
      text-align: center;
    }

    .countdown-text {
      font-size: 30px;
      animation-name: flash;
      animation-duration: $animation-duration;
      animation-iteration-count: infinite;
    }

    .timer {
      background: $grey-100;
      border-radius: 24px;
      padding: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .paused {
      background-color: $blue-400;
      color: $white;
      &:hover {
        background-color: $blue-400;
      }
    }
  }

  .video-recorder-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    #video-toggle {
      margin-right: 14px;
    }
  }

  .icon-red {
    color: $salmon-500;
    opacity: 1;
    margin-right: 10px;
  }

  .icon-change-camera {
    top: 12px;
    right: 12px;
  }

  .loader {
    border: 5px solid lightgrey;
    border-radius: 50%;
    border-top: 5px solid var(--accent-color);
    width: 80px;
    height: 80px;
    animation: spin 0.75s linear infinite;
    margin: auto;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* The animation code */
  @keyframes flash {
    0% {
      transform: scale(1.75);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
